<template>
    <div class="secondDetail">
        <Morelvweb @goBack="tabId" :tabBar="tabBarType"></Morelvweb>
        <div v-if="tabBarType === 0 || tabBarType === 1" class="tab-view">
            <div class="result-panel">
                <CSTable>
                    <template v-slot:thead>
                        <tr>
                            <th>文件名称</th>
                            <th>操作</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="(annex,index) in targetIdInfo" :key="index">
                            <td style="font-size:14px;">{{annex.name.slice(annex.name.indexOf('/')+1)}}</td>
                            <td>
                                <button
                                    class="btn btn-primary dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >操作</button>
                                <ul class="dropdown-menu">
                                    <li v-if="annex.type === 1" @click="checkFile(annex)">
                                        <a style="width:100%">查看</a>
                                    </li>
                                    <li @click="downloadFile(annex)">
                                        <a style="width:100%">下载</a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </div>
        </div>
        <div v-else-if="tabBarType === 2" class="tab-view">
            <div class="result-panel">
                <CSTable>
                    <template v-slot:thead>
                        <tr>
                            <th>物品名称</th>
                            <th>品牌型号</th>
                            <th>物品类型</th>
                            <th>存放位置</th>
                            <th>创建人</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="target in targetIdInfo" :key="target.id" style="font-size:14px;">
                            <td>{{target.name}}</td>
                            <td>{{target.model}}</td>
                            <td>{{target.typeName}}</td>
                            <td>{{target.specificLocation}}</td>
                            <td>{{target.createUser}}</td>
                        </tr>
                    </template>
                </CSTable>
            </div>
        </div>
        <div v-else-if="tabBarType === 3" class="tab-view" style="margin-top: 20px">
            <div class="filter-panel">
                <CSSelect>
                    <select v-model="searchTerm.resultState">
                        <option selected value="">请选择盘点结果</option>
                        <option v-for="result in resultStateList" :value="result.id" :key="result.id">{{result.name}}</option>
                    </select>
                </CSSelect>
                <button @click="mission()" class="btn btn-primary btn-sm" type="button">查询</button>
            </div>
            <div class="result-panel">
                <CSTable>
                    <template v-slot:thead>
                        <tr>
                            <th>物品名称</th>
                            <th>实际库存数量</th>
                            <th>库存数量</th>
                            <th>盘点结果</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="target in targetIdInfo" :key="target.id" style="font-size:14px;">
                            <td>{{target.goodsName}}</td>
                            <td>
                                    <span v-if="target.currentCount">
                                        {{target.currentCount}}
                                    </span>
                                <span v-else>
                                        -
                                    </span>
                            </td>
                            <td>
                                    <span v-if="target.inventoryQuantity">
                                        {{target.inventoryQuantity}}
                                    </span>
                                <span v-else>
                                        -
                                    </span>
                            </td>
                            <td>
                                    <span v-if="target.resultState">
                                        {{target.resultStateName}}{{ target.resultState !== 3 ? ' : ' + target.result : ''}}
                                    </span>
                                <span v-else>
                                        -
                                    </span>
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </div>
        </div>
        <div v-else-if="tabBarType === 4" class="tab-view">
            <div class="result-panel">
                <CSTable>
                    <template v-slot:thead>
                        <tr>
                            <th>文件名称</th>
                            <th>操作</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="(target,index) in targetIdInfo" :key="index" style="font-size:14px">
                            <td>{{target.name.slice(target.name.indexOf("/")+1) }}</td>
                            <td>
                                <button
                                    class="btn btn-primary dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-if="target.type === 1" @click="checkFile(target)">
                                        <a class="width:100%">查看</a>
                                    </li>
                                    <li @click="downloadFile(target)">
                                        <a class="width:100%">下载</a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </div>
        </div>
          <ViewImageModal />
    </div>
</template>

<script>
import Morelvweb from "@/components/warehouse/moreLvWeb";
import CSSelect from "@/components/common/CSSelect";
import ViewImageModal from "@/components/ViewImageModal"

import {STORAGE_KEY} from "@/constant";

import {
    missionDutyUrl,
    warehouseItemUrl,
    outWarehouseUrl,
    inWarehouseUrl,
    warehouseSupplierUrl,
    querySingleUrl, downloadMissionUrl, commonImgOssDomain, downloadFileOssDomain,
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
    components:{
        CSTable,
        Morelvweb,
        CSSelect,
        ViewImageModal,
    },
    props: {
        tabBar: Number,
        id: Number,
    },
    data(){
        return {
            searchTerm:{
                resultState:"",
            },
            targetIdInfo:{},
            resultStateList:[
                {id:1,name:"盘盈"},
                {id:2,name:"盘亏"},
                {id:3,name:"持平"},
            ],
            itemTypeList: [
                {id:1,name:"零件"},
                {id:2,name:"消耗品"},
                {id:3,name:"工具"},
                {id:4,name:"工服"},
                {id:5,name:"其它"}
		    ],
            tabBarType:0,
        }
    },
    created(){
        this.tabBarType = this.tabBar;
        switch(this.tabBar){
            case 0:
            case 1:
                this.warehouse();
                break;
            case 2:
                this.supplier();
                break;
            case 3:
                this.mission();
                break;
            case 4:
                this.missionFile();
                break;
        }
    },
    methods:{
        tabId(data){
            if(data === 1 || data === 2){
                sessionStorage.setItem(STORAGE_KEY.WAREHOUSE_TABID,data);
            }
            this.$router.back();
        },
        handleFile(resList,goodsId){
            for(var i =0;i<resList.length;i++){
                if(goodsId === resList[i].id){
                    this.targetIdInfo = JSON.parse(resList[i].annex);
                    console.log(this.targetIdInfo)
                    break;
                }
            }
        },
        jugeFileType(){
            var files = this.targetIdInfo;
            for(var i = 0;i<files.length;i++){
                if(/.(gif|jpg|jpeg|png|gif|jpg|png|jfif|webp)$/.test(files[i])){
                    files[i] = {type:1,name:files[i]};
                }else{
                    files[i] = {type:2,name:files[i]};
                }
            }
        },
        warehouse(){
            var tab = this.tabBar;
            var goodsId = this.id;
            if(tab === 0){
                this.$fly.post(outWarehouseUrl,{
                    regionCode:this.$vc.getCurrentRegion().communityId,
                    goodsId:"",
                    goodsType:"",
                    supplierId:"",
                    causeType:"",
                    type:"",
                    receiver:"",
                })
                .then(res=>{
                    var resList = res.data.datas || res.data;
                    this.handleFile(resList,goodsId)
                    this.jugeFileType();
                })
            }else{
                this.$fly.post(inWarehouseUrl,{
                    regionCode:this.$vc.getCurrentRegion().communityId,
                    goodsId:"",
                    goodsType:"",
                    supplierId:"",
                    acceptor:"",
                })
                .then(res=>{
                    var resList = res.data.datas || res.data;
                    this.handleFile(resList,goodsId)
                    this.jugeFileType();
                })
            }
        },
        supplier(){
            var id = this.$route.params.id;
            this.$fly.post(warehouseItemUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                search:"",
                state:"",
                locationId:"",
                type:"",
                supplierId:id,
                isWarning:"",
            })
            .then(res=>{
                this.targetIdInfo = res.data.datas || res.data;
                var dataArr = this.targetIdInfo;
                var toolArr = this.itemTypeList;
                for(var i = 0;i<dataArr.length;i++){
                    for(var j = 0;j<toolArr.length;j++){
                        if(dataArr[i].type === toolArr[j].id){
                            dataArr[i].typeName = toolArr[j].name;
                            break;
                        }
                    }
                }
            })
        },
        mission(){
            var id = this.$route.params.id;
            this.$fly.post(missionDutyUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                id,
                resultState:this.searchTerm.resultState,
            })
            .then(res=>{
                this.targetIdInfo = res.data.datas || res.data;
                this.targetIdInfo.forEach(mission=>{
                    this.resultStateList.forEach(result=>{
                        if(mission.resultState === result.id){
                            mission.resultStateName = result.name;
                        }
                    })
                })
            })
        },
        missionFile(){
            var goodsId = this.$route.params.id;
            this.$fly.post(warehouseSupplierUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
            })
            .then(res=>{
                var resList = res.data.datas || res.data;
                this.handleFile(resList,goodsId)
                this.jugeFileType();
            })
        },
        checkFile(target){
            this.$vc.emit(this.$route.path, "viewImageModal", "view", commonImgOssDomain + target.name);
        },
        downloadFile(target){
            window.open(downloadFileOssDomain + target.name);
        }
    }
}
</script>

<style>

</style>
